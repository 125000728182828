///*! matchMedia() polyfill - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas, David Knight. Dual MIT/BSD license */
if (!window.matchMedia) {
    window.matchMedia = (function () {
        "use strict";

        // For browsers that support matchMedium api such as IE 9 and webkit
        var styleMedia = (window.styleMedia || window.media);

        // For those that don't support matchMedium
        if (!styleMedia) {
            var style = document.createElement('style'),
                script = document.getElementsByTagName('script')[0],
                info = null;

            style.type = 'text/css';
            style.id = 'matchmediajs-test';

            script.parentNode.insertBefore(style, script);

            // 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
            info = ('getComputedStyle' in window) && window.getComputedStyle(style, null) || style.currentStyle;

            styleMedia = {
                matchMedium: function (media) {
                    var text = '@media ' + media + '{ #matchmediajs-test { width: 1px; } }';

                    // 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
                    if (style.styleSheet) {
                        style.styleSheet.cssText = text;
                    } else {
                        style.textContent = text;
                    }

                    // Test if media query is true or false
                    return info.width === '1px';
                }
            };
        }

        return function (media) {
            return {
                matches: styleMedia.matchMedium(media || 'all'),
                media: media || 'all'
            };
        };

    }());
}


/**
 * Created by Timo Gelhard on 16.01.14.
 * @version 1.3
 *
 * 1.2:
 * checkMedia als Objekt
 * detectApple als Objekt
 * checkDevice neu hinzugefügt
 * changeAlpha eingefügt
 *
 * 1.3:
 * user Agent Windows Phone geändert
 */

/**
 * From jQuery-----------------------------------------------
 */
// Avoid `console` errors in browsers that lack a console.
(function () {
    'use strict';
    var method;
    var noop = function () {
    };
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());
// Place any jQuery/helper plugins in here.
/**
 * End from jQuery-----------------------------------------------
 */


/**
 * checkMedia
 * Device, Betriebssystem, Mobile, Tablet prüfen
 */
var checkDevice = (function () {
    'use strict';
    return {
        android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        androidPhone: function () {
            return navigator.userAgent.match(/Android.+Mobile/i);
        },
        androidTablet: function () {
            return (checkDevice.android() && !checkDevice.androidPhone());
        },
        blackberry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iPhone: function () {
            return navigator.userAgent.match(/iPhone/i);
        },
        iPod: function () {
            return navigator.userAgent.match(/iPod/i);
        },
        iPad: function () {
            return navigator.userAgent.match(/iPad/i);
        },
        iOS: function () {
            return (checkDevice.iPhone() || checkDevice.iPod() || checkDevice.iPad());
        },
        opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        windowsPhone: function () {
            return navigator.userAgent.match(/IEios/i) || navigator.userAgent.match(/Windows Phone/i);
        },
        touch: function () {
            return (checkDevice.android() || checkDevice.blackberry() || checkDevice.iOS() || checkDevice.opera() || checkDevice.windowsPhone());
        },
        tablet: function () {
            return (checkDevice.iPad() || checkDevice.androidTablet());
        },
        mobile: function () {
            return (checkDevice.touch() && !checkDevice.tablet());
        },
        safari: function () {
            return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        },
        ie6: function () {
            return navigator.userAgent.match(/MSIE\s6/i);
        },
        ie7: function () {
            return navigator.userAgent.match(/MSIE\s7/i);
        },
        ie8: function () {
            return navigator.userAgent.match(/MSIE\s8/i);
        },
        ie9: function () {
            return navigator.userAgent.match(/MSIE\s9/i);
        },
        ie10: function () {
            return navigator.userAgent.match(/MSIE\s10/i);
        },
        ie9before: function () {
            return (checkDevice.ie8() || checkDevice.ie7() || checkDevice.ie6());
        },
        ie10before: function () {
            return (checkDevice.ie9before() || checkDevice.ie9());
        },
        ie9After: function () {
            return (checkDevice.ie10() || checkDevice.ie9() || navigator.userAgent.match(/Trident\/[7-9].[0-9]/i));
        }
    };
})();

/**
 * Media Querys prüfen und Ausgabe der Größe als
 * xs, sm, md, lg oder true|false bei Einzelprüfung
 *
 * Aufruf mit:
 * checkMedia.xs()
 * checkMedia.size()
 */
var checkMedia = (function () {
    'use strict';
    return {
        xxs: function () {
            return matchMedia('only screen and (max-width: 480px)').matches;
        },
        xs: function () {
            return matchMedia('only screen and (max-width: 767px)').matches;
        },
        sm: function () {
            return matchMedia('only screen and (min-width: 768px) and (max-width: 991px)').matches;
        },
        md: function () {
            return matchMedia('only screen and (min-width: 992px) and (max-width: 1199px)').matches;
        },
        lg: function () {
            return matchMedia('only screen and (min-width: 1200px)').matches;
        },
        xlg: function () {
            return matchMedia('only screen and (min-width: 1400px)').matches;
        },
        xxlg: function () {
            return matchMedia('only screen and (min-width: 2000px)').matches;
        },
        size: function () {
            if (matchMedia('only screen and (max-width: 767px)').matches) {
                return 'xs';
            }
            if (matchMedia('only screen and (min-width: 768px) and (max-width: 991px)').matches) {
                return 'sm';
            }
            if (matchMedia('only screen and (min-width: 992px) and (max-width: 1199px)').matches) {
                return 'md';
            }
            if (matchMedia('only screen and (min-width: 1200px)').matches) {
                return 'lg';
            }
        }
    };
})();


/**
 * Für Alpha Änderung in Animationen Greensock jQuery
 * @param color als rgba(0,0,0,0.5)
 * @param alpha als 0.8
 */
function changeAlpha(color, alpha) {
    'use strict';
    var values = color.split(',');
    return values[0] + ', ' + values[1].trim() + ', ' + values[2].trim() + ', ' + alpha + ')';
}

/**
 * URL Parameter ausgeben
 * @param sParam
 * @returns {*}
 */
function getUrlParameter(sParam) {
    'use strict';
    var sPageURL = decodeURIComponent(window.location.search.substring(1));
    var sURLVariables = sPageURL.split('&');
    var i;
    for (i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1];
        }
    }
}

/**
 * Resize End Event
 * mit
 * jQuery(window).bind('resizeEnd', function(){});
 */
jQuery(window).resize(function () {
    'use strict';
    if (this.resizeTO) {
        clearTimeout(this.resizeTO);
    }
    this.resizeTO = setTimeout(function () {
        jQuery(this).trigger('resizeEnd');
    }, 300);
});

/**
 * Strip Tags
 */
function stripTags(input, allowed) {
    'use strict';
    allowed = (((allowed || '') + '')
        .toLowerCase()
        .match(/<[a-z][a-z0-9]*>/g) || [])
        .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '')
        .replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
}

/**
 * Canvas Support
 */
function isCanvasSupported() {
    'use strict';
    var elem = document.createElement('canvas');
    return !!(elem.getContext && elem.getContext('2d'));
}

/**
 * Class ValidatorGeld
 *
 * Felder auf Geldeingabe prüfen
 * 23.09.2015
 */
var ValidatorGeld = (function () {
    'use strict';

    return {


        /**
         * Global für alle Felder mit der Klasse die Formatierungsfunktion setzen
         * new ValidatorGeld().init(jQuery('body'),'geld-feld',2);
         *
         * @param {jQuery.selector} $body || wird mit jQuery('body') erzeugt
         * @param {string} feld || 'geld-feld' mit class="geld-feld"
         * @param {integer} runden || 0, 1 oder 2
         */
        init: function ($body, feld, runden) {
            var object = this;
            runden = runden || 2;

            // Erlaubte Tasten setzen
            var tasten;
            if (runden > 0) {
                tasten = [8, 9, 13, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 188];
            }
            else {
                tasten = [8, 9, 13, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 188];
            }

            // Bei Tastenanschlag
            $body.on('keydown', feld, function () {
                var pruefung = false;
                for (var i = 0; i < tasten.length; i++) {
                    if (tasten[i] === event.keyCode) {
                        pruefung = true;
                    }
                }
                if (pruefung === false) {
                    return false;
                }
            });

            // Beim Copy und Paste
            $body.on('paste', feld, function (event) {
                event.preventDefault();
            });

            // Beim Verlassen Wert auf 0,00 formatieren
            $body.on('blur', feld, function () {
                jQuery(this).val(parseFloat(jQuery(this).val()).toFixed(runden));
            });

            object.formatFields(feld);

        },

        /**
         * Bei Aufruf der Seite oder nach Ajax Call Felder formatieren
         * new ValidatorGeld().formatFields('geld-feld',2);
         *
         * @param {string} feld || 'geld-feld' mit class="geld-feld"
         * @param {integer} runden || 0, 1 oder 2
         */
        // Bei Seitenaufruf Felder formatieren
        formatFields: function (feld, runden) {
            runden = runden || 2;
            jQuery(feld).each(function () {
                jQuery(this).val(parseFloat(jQuery(this).val()).toFixed(runden));
            });
        }

    };

})();

/**
 * Kontrastfarbe berechnen
 * Tendenz zu schwarz
 * @param hexcolor
 * @returns {string}
 */
var getContrast50 = function (hexcolor){
    'use strict';
    return (parseInt(hexcolor, 16) > 0xffffff/2) ? 'black':'white';
};

/**
 * Kontrastfarbe berechnen
 * Tendenz zu weiß
 * @param hexcolor
 * @returns {string}
 */
var getContrastYIQ = function (hexcolor){
    'use strict';
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
};


