var $body,
    $window,
    sizeAktuell,
    overlay = {
        objekt: null,
        fadeIn: null,
        fadeOut: null
    };

jQuery(document).ready(function(){
    'use strict';

    (function (jQuery) {
        $body = jQuery('body');
        $window = jQuery(window);
        overlay.objekt = jQuery('.overlay');
    }(jQuery));

    /*- Resize -----------------------------------------------------------------------*/

    (function (jQuery) {

        sizeAktuell = checkMedia.size();

        jQuery(window).resize(function () {
            if (sizeAktuell !== checkMedia.size()) {
                if ((sizeAktuell === 'xs' && checkMedia.size() === 'sm') || (sizeAktuell === 'sm' && checkMedia.size() === 'xs')) {
                    // setTimeout(function () {
                    //     location.reload();
                    // }, 1);
                    $body.trigger('changeSizeXS');
                }
                sizeAktuell = checkMedia.size();
                $body.trigger('changeSize');
            }
        });

    }(jQuery));


    /*- Overlay -----------------------------------------------------------------------*/

    (function (jQuery) {

        /**
         * Overlay einblenden und Body fixieren
         */
        overlay.fadeIn = function () {
            // set CSS
            TweenMax.set(overlay.objekt, {
                display: 'block',
                opacity: 0
            });
            // FadeIn
            TweenMax.to(overlay.objekt, 0.3, {
                opacity: 1
            });
            // Scrollbar setzen
            var scrollBarWidth = window.innerWidth - $body.width();
            $body.css({
                paddingRight: scrollBarWidth || 0
            }).addClass('modal-open');
        };

        /**
         * Overlay ausblenden und Body zurück setzen
         */
        overlay.fadeOut = function () {
            // FadeOut
            TweenMax.to(overlay.objekt, 0.2, {
                opacity: 0,
                onComplete: function () {
                    // set CSS
                    TweenMax.set(overlay.objekt, {
                        display: 'none'
                    });
                }
            });
            // Body zurück setzen
            $body.removeClass('modal-open').removeAttr('style');
        };

        /**
         * Overlay Event
         */
        overlay.objekt.click(function () {
            $body.trigger('overlay-click');
        });


    }(jQuery));

});