/**
 * Firma: TRX-Webdesign
 * Benutzer: Timo Gelhard
 * Datum: 06.03.2017
 * Uhrzeit: 16:05
 * Erstellt mit PhpStorm.
 */

jQuery(document).ready(function () {
    'use strict';

    /*- Navigation -----------------------------------------------------------------------*/
    (function (jQuery) {

        // Elemente zuordnen
        var $btnMenuOpen = jQuery('.btn-navi-menu'),
            $btnMenuClose = jQuery('.btn-navi-close'),

            $containerNavi = jQuery('.haupt-navi-rahmen-inner'),
            $swipeContainer = jQuery('.swipe-container'),

            $li = jQuery('.haupt-navi-nav-rahmen > ul > li'),
            $ul = $li.find('ul'),
            $liSubmenu,

            timeoutNavi,
            itemAktuell
        ;

        /*- Klassen erzeugen -----------------------------------------------------------------------*/
        $li.each(function () {
            var $item = jQuery(this);
            if ($item.children('ul').length > 0) {
                $item.addClass('submenu');
            }
        });
        $liSubmenu = jQuery('.submenu');

        /*- Menü Wechsel -----------------------------------------------------------------------*/
        /**
         * CSS reset für Desktop
         */
        var setDesktop = function () {
            TweenMax.set($ul, {
                css: {
                    visibility: 'hidden',
                    opacity: 0,
                    marginTop: 30
                }
            });
        };

        /**
         * CSS reset für Mobile
         */
        var setMobile = function () {
            TweenMax.set($ul, {
                css: {
                    visibility: 'inherit',
                    opacity: 1,
                    marginTop: 0
                }
            });
        };

        /*- Desktop Menü -----------------------------------------------------------------------*/

        /**
         * Wenn !disable-hover-on-touch oder kein touch Gerät
         * Hover Menü anzeigen
         */
        if (!Boolean($containerNavi.data('disable-hover-on-touch')) || !checkDevice.touch()) {
            /**
             * Hover Menü für Desktop
             */
            $li.hover(function () {

                if (!checkMedia.xs()) {

                    var $item = jQuery(this),
                        $ul = $item.find('ul');

                    // itemAktuell setzen für Timeout
                    if ($item.index() === itemAktuell) {
                        clearTimeout(timeoutNavi);
                    }
                    itemAktuell = $item.index();

                    // Set CSS, wenn display === none
                    if ($ul.css('visibility') === 'hidden') {
                        TweenMax.set($ul, {
                            css: {
                                visibility: 'inherit',
                                opacity: 0
                            }
                        });
                    }

                    // Animation In
                    TweenMax.to($ul, 0.3, {
                        css: {
                            marginTop: 5,
                            opacity: 1
                        },
                        ease: Power2.easeOut
                    });

                    // Add class
                    $item.addClass('hover');
                }

            }, function () {

                if (!checkMedia.xs()) {

                    var $item = jQuery(this),
                        $ul = $item.find('ul');

                    // Timeout setzen wegen Space zwischen Elementen
                    timeoutNavi = setTimeout(function () {

                        // Remove Class
                        $item.removeClass('hover');

                        // Animation Out
                        TweenMax.to($ul, 0.1, {
                            css: {
                                marginTop: 30,
                                opacity: 0
                            },
                            ease: Power2.easeIn,
                            onComplete: function () {
                                // Reset CSS, wenn Animation fertig ist
                                TweenMax.set($ul, {
                                    css: {
                                        visibility: 'hidden'
                                    }
                                });
                            }
                        });

                    }, 100);
                }

            });
        } else { // Klick Toggle Touch


            // Beim Klick außerhalb des Elements offene Fenster ausblenden
            $body.on('click touchstart', function (event) {
                if (
                    !checkMedia.xs() &&
                    !jQuery(event.target).parents('li:eq(0)').hasClass('hover') &&
                    !jQuery(event.target).parents('li:eq(1)').hasClass('hover')
                ) {                    // Animation Out
                    $liSubmenu.each(function () {
                        var $item = jQuery(this),
                            $ul = $item.find('ul');

                        // Remove Class
                        $item.removeClass('hover');

                        TweenMax.set($ul, {
                            css: {
                                marginTop: 30,
                                opacity: 0,
                                visibility: 'hidden'
                            }
                        });
                    });
                }
            });

            $liSubmenu.find('> a').click(function (event) {
                if (!checkMedia.xs()) {

                    event.preventDefault();

                    // li = parent
                    var $item = jQuery(this).parent(),
                        $ul = $item.find('ul');

                    if (!$item.hasClass('hover')) {

                        // Set CSS, wenn display === none
                        if ($ul.css('visibility') === 'hidden') {
                            TweenMax.set($item.find('ul'), {
                                css: {
                                    visibility: 'inherit',
                                    opacity: 0
                                }
                            });
                        }

                        // Animation In
                        TweenMax.set($ul, {
                            css: {
                                marginTop: 5,
                                opacity: 1
                            }
                        });

                        // Add class
                        $item.addClass('hover');

                    } else {

                        // Remove Class
                        $item.removeClass('hover');

                        // Animation Out
                        TweenMax.set($ul, {
                            css: {
                                marginTop: 30,
                                opacity: 0,
                                visibility: 'hidden'
                            }
                        });
                    }
                }

            });
        }


        /*- Mobile Menü -----------------------------------------------------------------------*/
        /**
         * Mobile Menü einblenden
         */
        var openNavi = function () {
            if (checkMedia.xs()) {
                overlay.fadeIn();
                $containerNavi.addClass('open');
                $btnMenuOpen.addClass('open');
            }
        };

        /**
         * Mobile Menü ausblenden
         */
        var closeNavi = function () {
            overlay.fadeOut();
            $containerNavi.removeClass('open');
            $btnMenuOpen.removeClass('open');
        };

        /*- Mobile Submenu Animation -----------------------------------------------------------------------*/
        /**
         * Submenu für Mobile Menü
         * Animation anzeigen wenn Wert gesetzt
         */
        if (Boolean($containerNavi.data('mobile-navigation'))) {

            $li.each(function () {
                jQuery(this).find('ul').wrap('<div class="submenu-rahmen"></div>');
            });

            $li.find('> a').click(function (event) {

                // li zum a
                var $itemLi = jQuery(this).parent(),
                    $itemUlRahmen = $itemLi.find('> .submenu-rahmen');

                // bei Mobile und wenn Untermenü vorhanden ist
                if (checkMedia.xs() && $itemLi.children('.submenu-rahmen').length > 0) {
                    event.preventDefault();
                    //     // $itemLi.toggleClass('open');

                    if ($itemLi.hasClass('open')) {
                        TweenMax.to($itemUlRahmen, 0.3, {
                            css: {
                                height: 0
                            }
                        });
                        $itemLi.removeClass('open');
                    } else {
                        TweenMax.set($itemUlRahmen, {
                            css: {
                                height: 'auto'
                            }
                        });
                        TweenMax.from($itemUlRahmen, 0.3, {
                            css: {
                                height: 0
                            }
                        });
                        $itemLi.addClass('open');
                    }
                }
            });

        }

        /*- Event Listener -----------------------------------------------------------------------*/
        // Event Listener
        $btnMenuOpen.click(openNavi);
        $btnMenuClose.click(closeNavi);
        $body.on('overlay-click', closeNavi);

        // Event Listener für Touch Geräte
        // if (checkDevice.touch()) {
        //     $swipeContainer.swiperight(openNavi);
        //     $containerNavi.swipeleft(closeNavi);
        // }

        // Event Listener Change Size bei Resize
        $body.on('changeSizeXS', function () {
            if (checkMedia.xs()) {
                setMobile();
            } else {
                setDesktop();
                overlay.fadeOut();
                closeNavi();
            }
        });


    }(jQuery));
});
